import request from '@/utils/request'
//获取游戏列表
export function getGameList(query){
	return request({
	  url: '/recommendBox/getInfo',
	  method: 'get',
	  params:query
	})
}
export function getGameBanner(query){
	return request({
	  url: '/banner/getInfo',
	  method: 'get',
	  params:query
	})
}
export function getSearch(query){
	return request({
	  url: '/banner/search',
	  method: 'get',
	  params:query
	})
}
export function getActivity(gameId){
	return request({
	  url: 'banner/activity/'+gameId,
	  method: 'get'
	})
}
export function getOrder(orderId){
	return request({
	  url: 'payerMax/verify/'+orderId,
	  method: 'get'
	})
}
export function getIntroduction(gameId){
	return request({
	  url: 'banner/strategy/'+gameId,
	  method: 'get'
	})
}
export function getIntroductionDetail(id){
	return request({
	  url: 'banner/details/'+id,
	  method: 'get'
	})
}
export function getNewsList(query){
	return request({
	  url: 'banner/message',
	  method: 'get',
	  params:query
	})
}
export function getNewsDetail(id){
	return request({
	  url: 'banner/messageDetails/'+id,
	  method: 'get'
	})
}
export function getDownloadUrl(gameId,platid){
	return request({
	  url: 'game/'+gameId+'/'+platid,
	  method: 'get'
	})
}
// 开服信息
export function getGameService(query){
	return request({
	  url: '/banner/getGameOpenServiceList/'+query,
	  method: 'get'
	})
}
// 公告
export function getNoticeList(query){
	return request({
	  url: '/banner/notice',
	  method: 'post',
	  data:query
	})
}
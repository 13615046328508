export default {
	common: {
		noMore: '没有更多了',
		noEmpty: '必传',
		copySuccess: '复制成功',
		copyFail: '复制失败',
		search: '搜索',
		remark: '备注',
		commit: '提交',
		status: '状态',
		revoke: '撤销',
		cancel: '取消',
		confirm: '确认',
		getbtn: '领取',
		selectTime: '选择时间',
		selectDate: '选择日期',
		uploadImage: '图片上传',
		uploadVideo: '视频上传',
		activity: '活动',
		problem: '问题',
		money: '金额',
		reply: '回复',
		other: '其他',
		send: '发送',
		copy: '复制',
		view: '查看',
		next: '下一步',
		feedback: '我要反馈',
		enter: '请输入',
		loading: '加载中...',
		searchkey: '请输入搜索关键词',
		back: '返回',
		download: '下载',
		detail: '详情',
		mUnit: '￥',
		thousand: '千',
		tenThousand: '万',
		tenMillion: '千万',
		billion: '亿',
		hundredBillion: '千亿',
		trillions: '万亿',
		quadrillions: '千万亿',
	},
	upload: {
		label: '上传',
		loading: '上传中...',
		success: '上传成功',
		fail: '上传失败',
		sizeTips: '文件大小不能超过 {size} M',
	},
	menu: {
		home: '首页',
		news: '最热门资讯',
		personal: '个人中心',
		coupon: '十万抵扣券',
		pay: '支付',
		clauseUser: '用户隐私协议',
		clauseService: '隐私政策',
		about: '关于我们',
		contact: '联系我们',
		refund: '退款协议',
		pcdownload: 'PC下载'
	},
	login: {
		name: '账号',
		pass: '密码',
		nameTips: '请填写用户名',
		passTips: '请填写密码',
		submitBtn: '提交',
		accountTitle: '账密登录',
		thirdTitle: '第三方登录',
		googleLogin: 'google登录'
	},
	home: {
		tips:'GM 官方kakao：GM20238',
		slogans:'모든 게임 50% 할인되는 정보 app',
		news: '最热门资讯',
		recommend: '最新福利资讯',
		recommendGame: '推荐游戏',
		recommendDesc: '官方推荐好游',
		hotGame: '热门游戏',
		hotDesc: '最火热门游戏',
		gameDownload: '游戏下载',
		pcDownload: 'PC下载',
		downloadUrl: '下载地址',
		activity: '活动',
		introduction: '攻略',
		offlineActivity: '线下活动',
		openService: '开服时间',
		releaseTime: '发布时间',
		gameNotice: '游戏公告',
		prebookGame: '新游预约',
		prebookDesc: '最新公开，新游首发',
		guidesTitle: '添加教程',
		btnText: '激活10万韩币',
		guidesText:'完成下方步骤可获得10万元韩币抵扣券',
		newstotal: '当前有 {num} 篇攻略',
		gamePlay: '开始游戏',
		couponRules: '使用说明'
	},
	pay: {
		title: '充值中心',
		orderMoney: '订单金额',
		allCoupon: '全额代金券',
		halfCoupon: '半额代金券',
		total: '合计',
		discount: '折扣',
		paybtn: '支付',
		authConfirm: '去实名',
		authCancel: '已实名',
		payJumpTitle: '支付跳转提示',
		payJumpText: '将离开此页面，是否确认去支付？',
		payRecharge: '立即充值',
		recharge: '充值',
		rechargeMoney: '充值金额',
		rechargeTitle: '选择充值金额',
		rechargePlaceholder: '请输入充值金额',
		tipsInputRecharge: '请重新选择或输入充值金额~',
		goodMoney: '商品价格',
		discountCoupon: '抵扣券',
		gmCoin: 'GM币',
		payType: '支付方式',
		discountMoney: '已优惠',
		maxDiscountTips: '已推荐最优惠方案',
		discountTitle: '请选择抵扣券',
		noUseDiscount: '不使用抵扣券',
		expTime: '到期时间',
		percentDiscount: '100%抵扣券',
		halfPercentDiscount: '50%抵扣券',
		onlyForthisGame: '仅限本游戏',
		fullReduction: '满{num}可用',
		gmCoinTitle: 'GM币使用',
		payTypeTitle: '选择支付方式',
		gmCoinTips: 'GM币可用于GM系列礼包兑换',
		allGMCoin: '现有GM币',
		useGMCoin: '消耗GM币',
		remainGMCoin: '剩余GM币',
		selectPayTitle: '选择支付方式',
		gmCoinPlaceholder: '请输入GM币（100起）',
		freeGetGM: '免费获得',
		needPayTips: '您输入的GM币和抵扣券叠加使用后，需另支付金额且不足100元，请你重新输入使用GM币或者重新选择抵扣券～',
		detail: '详情',
		payTypeTips: '抵扣券和GM币不能同时用',
		payMaxLimit: '最高抵扣{num}',
		payLimitTips: '没有剩余金额能用GM币抵扣啦～'
	},
	personal: {
		platId: '平台ID',
		platCoin: '平台币',
		myPlatCoin: '当前平台币',
		loginBtn: '登录',
		loginOut: '退出登录',
		googleEmail: 'google邮箱',
		tipsLogin: '请您先登录～',
		commonUser:'普通用户',
		vipDetail:'查看特权'
	},
	footer: {
		aboutus: '公司介绍',
		contact: '客服支持',
		service: '隐私政策',
		refund: '退款协议',
		userService: '用户协议',
		telLabel: '电话号码',
		telValue: '01088727088',
		licenseNumLabel: '营业执照号',
		licenseNumValue: '387-81-02165',
		companyLabel: '公司名称',
		companyValue: '桂圆',
		legalPersonLabel: '代表名',
		legalPersonValue: 'YU CHAOSHUYI',
		emailLabel: '邮箱',
		emailValue: "hongdenglonghk0330{'@'}gmail.com",
		bankLabel: '银行账号',
		bankValue: 'KEB HANA BANK 189-910052-14504',
		businessNumLabel: '邮购业务编号',
		businessNumValue: '제2023-서울강남-03332호',
		addressLabel: '地址',
		addressValue: '서울특별시 강남구 영동대로 602,6층 에이치41(삼성동,미켈라 107)',
		support: '© 2023 Powered by GM',
		useManual: '使用说明'
	}
}
import request from '@/utils/request'
import { encryptByDES } from '@/utils/3DES.js'
// 谷歌登录
export function googleLogin(data) {
  return request({
    url: '/google/boxGoogle',
    headers: {
      isToken: false,
	  isMallApi: true
    },
    transformRequest:[function(data){
    	return data
    }],
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}
// 母包登录
export function testLogin(data) {
  return request({
    url: '/google/userTest',
    headers: {
      isToken: false,
	  isMallApi: true
    },
    transformRequest:[function(data){
    	return data
    }],
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}
// 道具商城用户登录
export function propUserLogin(token) {
  return request({
    url: '/google/skip',
    headers: {
	  isToken: false,
	  isMallApi: true,
	  Identification: token
    },
    method: 'get'
  })
}
// 盒子信息
export function gmInfo() {
  return request({
    url: '/google/boxInfo',
    headers: {
	  isToken: true,
	  isMallApi: true
    },
    method: 'get'
  })
}
// 游戏登录
export function gameLogin(gameId) {
  return request({
    url: '/google/googleGame/'+gameId,
    headers: {
	  isToken: true,
	  isMallApi: true
    },
    method: 'get'
  })
}
// 充值余额
export function getPay(data) {
  return request({
    url: '/function/order/pay',
    method: 'post',
	headers: {
		isToken: false,
		isMallApi: true
	},
	data: encryptByDES(JSON.stringify(data))
  })
}
// 查询vip
export function getVipInfo(id) {
  return request({
    url: '/vip/userRela/findUserRelaDetails/' + id,
    method: 'get',
	headers: {
		isToken: false,
	},
  })
}
